/* You can add global styles to this file, and also import other style files */
/* regular style toast */
/* @import '~ngx-toastr/toastr'; */
/* .int-casino-slider { 
    width: 500px;
} */
.int-casino-slider .swiper-container {
    /* width: 100%;
    position: relative; */
    overflow: hidden;
    /* list-style: none;
    padding: 0;
    z-index: 1; */
}

.table-remark.remark {
	font-size: 13px;
}

.dn { display: none; }
.text-green { color: green; }
.text-red { color: red; }

.casino__details iframe{
    height: calc(100vh - 98px);
    width: 100%;
}
.page-item.active .page-link {
  background-color: #2c737e !important;
  border-color: #2c737e !important;
}
typeahead-container{
    width: 100% !important;
    left: 0 !important;
    transform: none !important;
    top: 34px !important;
    border-radius: 0 !important;
    padding: 0 !important;
    height: 200px !important;
}
typeahead-container button.active{
    background: none !important;
}
typeahead-container button{
    border-bottom: 1px solid #eaeaea !important;
    padding: 15px !important;
    background: none !important;
    color: #000 !important;
    text-align: left !important;
    line-height: normal !important;
    border-right: none !important;
    border-left: none !important;
    border-radius: 0 !important;
}
typeahead-container button a{
    color: #000 !important;
}
typeahead-container button:last-child{
    border-bottom: none !important;
}
typeahead-container button:hover{
    box-shadow: none !important;
    border-color: #ddd !important;
    background: #eee !important;
}

.cs_divs_position typeahead-container {
    width: 30% !important;
    top: 33px !important;
    height: 150px !important;
    overflow-y: auto !important;
    border-radius: 4px !important;
    z-index: 3 !important;
}
.cs_divs_position typeahead-container button {
    padding: 8px 11px !important;
    border: none !important;
    white-space: break-spaces;
}
.cs_divs_position typeahead-container button h5 {
    font-size: 15px;
    font-weight: 300;
    color: #fff;
    margin: 0;
}

.btn-custom-loader-d, .btn-custom-loader-m { position: relative; }

.btn-custom-loader-m i { top: 7px; right: 0; left: 0; position: absolute; }
.btn-custom-loader-d i { top: 10px; right: 0; left: 0; position: absolute; }
.LiveStream-video-col .bgimage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.competition-head {

}
.competition-head h3 {
    width: 100%;
    display: block;
    background: var(--theme2-bg);
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    padding: 2px 14px;
    color: var(--secondary-color);
}

@media screen and (max-width: 767px) {
    /*.int-casino-slider { 
        width: 100% !important;
    }
    .int-casino-slider img {
        width: 90%;
    }*/
    typeahead-container{
        width: 100% !important;
        left: 0 !important;
        transform: none !important;
        top: 56px !important;
        border-radius: 0 !important;
        padding: 0 !important;
        height: 300px !important;
    }
    typeahead-container button.active{
        background: none !important;
    }
    typeahead-container button{
        border-bottom: 1px solid #eaeaea !important;
        padding: 15px !important;
    }
    typeahead-container button a{
        color: #000 !important;
    }
}